import React, { useState, useEffect } from "react";
import { TrashIcon } from "@heroicons/react/outline";
import axios from "axios";

function Form() {
  const [customerID, setCustomerID] = useState(null);
  const [storeList, setStoreList] = useState(null);
  const [votedStoreList, setVotedStoreList] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [storeNameInptVisible, setStoreNameInptVisible] = useState(false);
  const [newStoreName, setNewStoreName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Get the query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const customer_id = queryParams.get("customer_id");
    setCustomerID(customer_id);
    console.log("customer_id:", customer_id);
    getStoresList();
    getVotedStoresList();
  }, []);

  const getStoresList = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const customer_id = queryParams.get("customer_id");
    axios
      .post(
        "https://alphacluster.digitalfields.co.za/diginu/api/v1/dummy/get-votable-stores",
        {
          customer_id: customer_id,
        }
      )
      .then(async (response) => {
        console.log("response", response.data);
        const stores = response.data.data;
        setStoreList(stores);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const getVotedStoresList = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const customer_id = queryParams.get("customer_id");
    axios
      .post(
        "https://alphacluster.digitalfields.co.za/diginu/api/v1/dummy/get-voted-stores",
        {
          customer_id: customer_id,
        }
      )
      .then(async (response) => {
        console.log("response", response.data);
        const stores = response.data.data;
        setVotedStoreList(stores);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const addStore = (e) => {
    e.preventDefault();
    console.log("selectedStore", selectedStore);
    if (selectedStore === "" || selectedStore === null) {
      alert("No store selected.");
      return;
    } else if (selectedStore === "new") {
      if (!newStoreName) {
        alert("Fill in a store name.");
      } else {
        addNewStore();
      }
    } else {
      voteForStore();
    }
  };

  const rvmStore = (storeID) => {
    setLoading(true);
    axios
      .post(
        "https://alphacluster.digitalfields.co.za/diginu/api/v1/dummy/del-voted-stores",
        {
          voting_id: storeID,
        }
      )
      .then(async (response) => {
        console.log("response", response.data);
        getVotedStoresList();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const addNewStore = () => {
    setLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const customer_id = queryParams.get("customer_id");
    axios
      .post(
        "https://alphacluster.digitalfields.co.za/diginu/api/v1/dummy/ins-vote-new-store",
        {
          customer_id: customer_id,
          display_name: newStoreName,
          all_access: true,
        }
      )
      .then(async (response) => {
        console.log("response", response.data);
        const newstoreID = response.data.data["store_id"];
        voteForStore(newstoreID);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const voteForStore = (storeID) => {
    const queryParams = new URLSearchParams(window.location.search);
    const customer_id = queryParams.get("customer_id");
    axios
      .post(
        "https://alphacluster.digitalfields.co.za/diginu/api/v1/dummy/ins-votable-stores",
        {
          customer_id: customer_id,
          votable_stores_id: storeID ? storeID : selectedStore,
        }
      )
      .then(async (response) => {
        console.log("response", response.data);
        getStoresList();
        getVotedStoresList();
        setSelectedStore("");
        setNewStoreName("");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedStore(selectedValue);
    console.log("selectedStore", selectedValue);
    if (selectedValue === "new") {
      setStoreNameInptVisible(true);
    } else {
      setStoreNameInptVisible(false);
    }
  };

  return (
    <form className="m-10  lg:mr-32 lg:ml-32">
      <div className="space-y-12 ">
        <div className=" pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Merchant Voting
          </h2>
          <p className="mt-1 mb-4 text-sm leading-6 text-gray-600">
            Please select a merchant you'd like to be added to diginu.
          </p>

          {/* Store name select */}
          <div className="sm:col-span-3">
            <label
              htmlFor="country"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Stores
            </label>
            <div className="mt-2">
              <select
                id="storesList"
                name="storesList"
                onChange={handleSelectChange}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-100 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value={""}>Please select an option</option>

                {storeList &&
                  storeList.map((store) => (
                    <option value={store.id}>{store.display_name}</option>
                  ))}

                <option value={"new"}>Add store not on this list</option>
              </select>
            </div>
          </div>

          {/* New store name input */}
          {storeNameInptVisible && (
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Store Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="newStoreName"
                    placeholder="Store"
                    value={newStoreName}
                    onChange={(e) => setNewStoreName(e.target.value)}
                    id="newStoreName"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Button */}
      <div className="flex items-center justify-end gap-x-6 border-b border-gray-900/10 pb-8">
        <button
          type="submit"
          className="rounded-md bg-primary-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-100"
          onClick={addStore}
        >
          Vote to Add Store
        </button>
      </div>

      {/* List selected store */}

      <h2 className="mt-8 text-base font-semibold leading-7 text-gray-900">
        Voted for stores:
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        Below is a list of stores you have voted for.
      </p>
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          {votedStoreList.length > 0 && (
            <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
              {votedStoreList.map((store) => (
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div className="flex w-0 flex-1 items-center">
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">
                        {store.display_name}
                      </span>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <TrashIcon
                      className="h-5 w-5 flex-shrink-0 text-primary-100"
                      aria-hidden="true"
                      onClick={() => rvmStore(store.voting_id)}
                    />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </dd>
      </div>

      {/* Loader */}
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-gray-900 bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-100"></div>
          <div className="text-white mt-6">Loading...</div>
        </div>
      )}
    </form>
  );
}

export default Form;
